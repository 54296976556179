'use client';

import { signIn, useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { Icon, IconName } from '~/components/core/Icon';
import Button from '~/components/customComponents/buttons/Button';
import Flex from '~/components/customComponents/flex/Flex';
import FormItem from '~/components/customComponents/formItem/FormItem';
import Text from '~/components/customComponents/texts/Text';
import { PASSWORD_CREATE_SUCCESS } from '~/constants/queryParams';
import { PASSWORD_REGEX } from '~/constants/regex';
import useFormServerAction from '~/hooks/useFormServerAction';
import { useI18n } from '~/locales/client';
import { FormItemTypeEnum } from '~/types-and-enums/formFieldEnums';
import {
  updateHasSeenModal,
  updateSearchParams,
  updateSearchParamsNew,
} from '~/utils/clientUtils';
import { colors, spacing } from '~/utils/tailwindUtils';
import { postSetPassword } from '../../setup-password/requests';
import { CreationAlert } from './CreationAlert';

type PasswordMatcherProps = {
  email?: string;
  mode: 'create' | 'reset' | 'update';
  isFirstTimeUser: boolean;
  isSetPasswordModal?: boolean;
};

/**
 * A password matcher component that handles password input, confirmation, and submission.
 *
 * @param {string} email - email address to display
 * @param {string} mode - mode of the component (e.g. 'reset', 'set')
 * @param {boolean} isFirstTimeUser - whether the user is a first-time user
 * @param {boolean} isSetPasswordModal - whether the component is used in a set password modal
 * @return {JSX.Element} the password matcher component
 */
const PasswordMatcher = ({
  email,
  mode,
  isFirstTimeUser,
  isSetPasswordModal,
}: PasswordMatcherProps) => {
  const translate = useI18n();
  const searchParams = useSearchParams();
  const { data } = useSession();
  const [mockLoading, setMockLoading] = useState(false);
  const user = data?.user;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(translate('page.general.error.new-password-required'))
      .matches(PASSWORD_REGEX, {
        message: translate('component.password-matcher.view.email.error'),
      }),
    repeat_password: yup
      .string()
      .required(translate('page.general.error.repeat-password-required'))
      .oneOf(
        [yup.ref('password')],
        translate('page.general.error.password-are-not-match')
      ),
  });

  const getNewSession = async () => {
    await signIn('credentials', {
      redirect: false,
      email: user?.email,
      password: formData.password,
    });
  };
  const { formAction, errors, isLoading, formData, setFormData } =
    useFormServerAction<{
      password: string;
      repeat_password: string;
    }>({
      action: async () => {
        setMockLoading(true);
        const res = await postSetPassword(formData);
        return res;
      },
      initialFormData: { password: '', repeat_password: '' },
      schema,
      onFinish: () => {
        setFormData({
          password: '',
          repeat_password: '',
        });
        getNewSession()
          .then(() => {
            if (isSetPasswordModal && isFirstTimeUser) {
              updateHasSeenModal('true');
              updateSearchParams('');
              // we reload to make sure that the button in settings page changes, this is just for 1 time. so no need to worry about performance
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              updateSearchParams(isFirstTimeUser ? '/?welcome=true' : '/');
            }
          })
          .finally(() => {
            updateSearchParamsNew(
              `${PASSWORD_CREATE_SUCCESS}=true`,
              searchParams
            );
          });
      },
    });

  const copy = useMemo(() => {
    return {
      title: isFirstTimeUser
        ? translate('component.password-matcher.view.title')
        : translate('page.reset-password.view.title'),
      description: translate('component.password-matcher.view.description'),
      label: translate('component.password-matcher.view.email.label'),
      placeholder: translate(
        'component.password-matcher.view.email.placeholder'
      ),
      confirmLabel: translate(
        'component.password-matcher.view.email.confirm.label'
      ),
      confirmPlaceholder: translate(
        'component.password-matcher.view.email.confirm.placeholder'
      ),
      errorLabel: translate('component.password-matcher.view.email.error'),
      errorConfirmLabel: translate(
        'component.password-matcher.view.email.confirm.error'
      ),
      submitButtonLabel: isFirstTimeUser
        ? translate(`component.password-matcher.view.${mode}.submit.button`)
        : translate('page.reset-password.view.button'),
    };
  }, [isFirstTimeUser, mode, translate]);

  return (
    <Flex className="min-w-full justify-center items-center flex-col">
      <Flex className="justify-center flex-col  min-h-full w-full md:w-96">
        <form action={formAction}>
          <Flex className="mb-6 md:mb-8">
            <Flex className="flex-col">
              <h2 className="text-2xl md:text-[28px] font-bold">
                {copy.title}
              </h2>
              <Text className="text-base mt-4 font-medium text-gray-800">
                {copy.description}
              </Text>

              {email && (
                <>
                  <Flex className="px-3 py-2 border border-gray-100 rounded-full items-center gap-2 mt-2">
                    <Icon
                      size={spacing[6]}
                      name={IconName.Mail}
                      color={colors.gray[900]}
                    />
                    <Text className="text-base font-medium text-gray-900">
                      {email}
                    </Text>
                  </Flex>
                  <Text className="text-xs text-gray-800 mt-2">
                    {translate('component.password-matcher.view.email.not.you')}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <Flex className="flex-col gap-y-4">
            <FormItem
              label={copy.label}
              name="password"
              placeholder={copy.placeholder}
              type={FormItemTypeEnum.Password}
              onChange={(value: string) => setFormData({ password: value })}
              value={formData.password}
              isInvalid={!!errors.password}
              errorMessage={translate(errors.password)}
              // errorMessage={copy.errorConfirmLabel}
            />
            <FormItem
              label={copy.confirmLabel}
              name="passwordConfirm"
              placeholder={copy.confirmPlaceholder}
              type={FormItemTypeEnum.Password}
              onChange={(value: string) =>
                setFormData({ repeat_password: value })
              }
              value={formData.repeat_password}
              isInvalid={!!errors.repeat_password}
              errorMessage={translate(errors.repeat_password)}
              // errorMessage={copy.errorConfirmLabel}
            />
          </Flex>
          <CreationAlert
            password={formData.password}
            showDisclaimer={isFirstTimeUser}
          />
          <Button
            buttonClassName="hover:bg-red-700"
            disabled={isLoading || mockLoading}
            isLoading={isLoading || mockLoading}
            type="submit"
            text={copy.submitButtonLabel}
          />
        </form>
      </Flex>
      {/* {subMissionError && (
        <Text className="text-xs text-red-500 mt-4">
          {translate(
            'component.password-matcher.view.submission.failure.title'
          )}
        </Text>
      )} */}
    </Flex>
  );
};

export default PasswordMatcher;
